import { ApiURL } from '../util-url';

export const getNotionPageService = (id: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		};
		fetch(ApiURL(`/api/third-party/notion/pages/${id}`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
