/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	PARTNER,
	ThunkCheckInUpsellingProducts,
	getWhatsappMe
} from '@hospy/store';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './airbnb-reservation.constants';
import {
	getAirbnbReservation,
	getAirbnbReservationFieldMapping,
	getAirbnbReservationProperty,
	initAirbnbReservation
} from './airbnb-reservation.thunks';

export const airbnbReservationSlice = createSlice({
	name: 'airbnbReservation',
	initialState,
	reducers: {
		setAirbnbReservation: (state, { payload }) => {
			state.reservation = payload;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAirbnbReservationProperty.pending, (action) => {
				action.property = {
					loading: 'pending'
				};
			})
			.addCase(
				getAirbnbReservationProperty.rejected,
				(action, { payload }) => {
					action.property = {
						loading: 'failed',
						error: String(payload)
					};
				}
			)
			.addCase(
				getAirbnbReservationProperty.fulfilled,
				(action, { payload }) => {
					action.property = {
						loading: 'succeeded',
						data: payload
					};
				}
			);

		builder
			.addCase(getAirbnbReservation.pending, (action, { payload }) => {
				action.reservation.loading = 'pending';
			})
			.addCase(getAirbnbReservation.rejected, (action, { payload }) => {
				action.reservation.loading = 'failed';
				action.reservation.error = String(payload);
			})
			.addCase(getAirbnbReservation.fulfilled, (action, { payload }) => {
				action.reservation.loading = 'succeeded';
				action.reservation.data = payload;
			});

		builder
			.addCase(
				getAirbnbReservationFieldMapping.pending,
				(state, action) => {
					if (
						action.meta.arg.partnerIntegrationId === PARTNER.TTLOCK
					) {
						state.fieldMappingTtlock.loading = 'pending';
					} else if (
						action.meta.arg.partnerIntegrationId ===
						PARTNER.FLEXIPASS
					) {
						state.fieldMappingFlexipass.loading = 'pending';
					}
				}
			)
			.addCase(
				getAirbnbReservationFieldMapping.rejected,
				(state, action) => {
					if (
						action.meta.arg.partnerIntegrationId === PARTNER.TTLOCK
					) {
						state.fieldMappingTtlock.loading = 'failed';
						state.fieldMappingTtlock.error = action.payload;
					} else if (
						action.meta.arg.partnerIntegrationId ===
						PARTNER.FLEXIPASS
					) {
						state.fieldMappingFlexipass.loading = 'failed';
						state.fieldMappingFlexipass.error = action.payload;
					}
				}
			)
			.addCase(
				getAirbnbReservationFieldMapping.fulfilled,
				(state, action) => {
					if (
						action.meta.arg.partnerIntegrationId === PARTNER.TTLOCK
					) {
						state.fieldMappingTtlock = {
							loading: 'succeeded',
							data: action.payload
						};
					} else if (
						action.meta.arg.partnerIntegrationId ===
						PARTNER.FLEXIPASS
					) {
						state.fieldMappingFlexipass = {
							loading: 'succeeded',
							data: action.payload
						};
					}
				}
			);

		builder
			.addCase(getWhatsappMe.pending, (state) => {
				state.whatsappMe.loading = 'pending';
			})
			.addCase(getWhatsappMe.rejected, (state, { payload }) => {
				state.whatsappMe.loading = 'failed';
				state.whatsappMe.error = payload;
			})
			.addCase(getWhatsappMe.fulfilled, (state, { payload }) => {
				state.whatsappMe.loading = 'succeeded';
				state.whatsappMe.data = payload;
			});

		builder
			.addCase(initAirbnbReservation.pending, (state) => {
				state.initReservation.loading = 'pending';
			})
			.addCase(initAirbnbReservation.rejected, (state, { payload }) => {
				state.initReservation.loading = 'failed';
				state.initReservation.error = payload;
			})
			.addCase(initAirbnbReservation.fulfilled, (state, { payload }) => {
				state.initReservation.loading = 'succeeded';
				state.initReservation.data = payload;
				//
				state.reservation.loading = 'succeeded';
				state.reservation.data = payload?.reservation;
				state.partnerReservation.data = payload?.partnerReservation;
				state.listing = {
					loading: 'succeeded',
					data: payload.listings
				};
				// state.listing
				if (payload.propertyIntegrations?.length > 0) {
					const checkInHostIntegration =
						payload.propertyIntegrations?.find(
							(e: any) =>
								e.partnerIntegrationType !== 'iot-lock' &&
								(e?.extras || []).length > 0 &&
								e?.extras?.find((i: any) => i?.slug === 'name')
						);

					if (checkInHostIntegration) {
						const { property, ...rest } = checkInHostIntegration;

						state.checkInGuestIntegration = {
							loading: 'succeeded',
							data: { ...rest }
						};

						state.property = {
							loading: 'succeeded',
							data: property
						};

						const TTLockIntegration =
							payload.propertyIntegrations.find(
								(e: any) =>
									e.partnerIntegrationId === PARTNER.TTLOCK
							);
						if (TTLockIntegration) {
							delete TTLockIntegration.property;
							state.ttLockIntegration = {
								loading: 'succeeded',
								data: { ...TTLockIntegration }
							};
						}

						const flexipassIntegration =
							payload.propertyIntegrations.find(
								(e: any) =>
									e.partnerIntegrationId === PARTNER.FLEXIPASS
							);
						if (flexipassIntegration) {
							delete flexipassIntegration.property;
							state.flexipassIntegration = {
								loading: 'succeeded',
								data: { ...flexipassIntegration }
							};
						}
					}
				}
			});

		builder
			.addCase(ThunkCheckInUpsellingProducts.pending, (state) => {
				state.upsellingProducts.loading = 'pending';
			})
			.addCase(
				ThunkCheckInUpsellingProducts.rejected,
				(state, { payload }) => {
					state.upsellingProducts = {
						loading: 'failed',
						error: payload
					};
				}
			)
			.addCase(
				ThunkCheckInUpsellingProducts.fulfilled,
				(state, { payload }) => {
					state.upsellingProducts = {
						loading: 'succeeded',
						data: payload
					};
				}
			);
	}
});

export const { setAirbnbReservation } = airbnbReservationSlice.actions;
