import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body,
#root {
    width: 100%;
    min-height: 100vh;
    font-family: AmikoRegular;
}

.ant-form-item .ant-form-item-label {
    padding: 0;
}
.ant-input-lg {
    font-size: 20px;
    padding: 15px;
}

.ant-select-selector {
    padding: 9px 15px !important;
}
.ant-select-lg {
    font-size: 20px;
}

.ant-form-item-control-input,
.ant-form-item-control-input .ant-input-number-input,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 63.03px;
}

.ant-form-item-control-input .ant-input-number-input,
.ant-btn-group .ant-btn-icon-only, .ant-form label {
    font-size: 20px;
}

.ant-radio-button-wrapper {
    padding: 15px;
    height: auto;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: 63.03px;
}

.ant-select-item-option-content,
.ant-picker-large .ant-picker-input>input {
    font-size: 20px;
}

.ant-select-item {
    padding: 15px 12px;
}

.ant-form-item-with-help {
    margin-bottom: 24px;
}

.react-tel-input input[disabled] {
    color: rgba(0,0,0,.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

.react-tel-input input[disabled] + .flag-dropdown  {
    opacity: 0.3;
}

body.modal-open {
  overflow: hidden;
}

b {
    font-family: AmikoRegularBold;
}
strong {
    font-family: AmikoRegularBold;
}

.ant-image-preview-switch-left, .ant-image-preview-switch-right {
    z-index: 9999;
}

.ant-select-dropdown.dark-mode {
    background-color: #333333;
}

.ant-select-dropdown.dark-mode .ant-select-item-option {
    color: #fff;    
}
.ant-select-dropdown.dark-mode .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
background-color: #131313;
}
.ant-select-dropdown.dark-mode .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #fff;
    font-weight: 600;
    background-color: #090909;
}
`;
