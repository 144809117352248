import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { airbnbReservationSlice } from './airbnb-reservation/airbnb-reservation.slice';
export const store = configureStore({
	reducer: {
		airbnbReservation: airbnbReservationSlice.reducer
	}
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
