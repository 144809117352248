import { AppRoute } from '@airbnb/components/app-route';
import { LoadingAppView } from '@hospy/shared-components';
import React from 'react';

const App = () => {
	return (
		<React.Suspense fallback={<LoadingAppView hideLogo />}>
			<AppRoute />
		</React.Suspense>
	);
};

export default App;
