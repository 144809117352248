import { AirbnbReservationState } from './airbnb-reservation.interfaces';

export const initialState = {
	property: { loading: 'idle' },
	checkInGuestIntegration: { loading: 'idle' },
	ttLockIntegration: { loading: 'idle' },
	flexipassIntegration: { loading: 'idle' },
	reservation: { loading: 'idle' },
	partnerReservation: { loading: 'idle' },
	listing: { loading: 'idle' },
	whatsappMe: { loading: 'idle' },
	initReservation: { loading: 'idle' },
	upsellingProducts: { loading: 'idle' },
	fieldMappingTtlock: { loading: 'idle' },
	fieldMappingFlexipass: { loading: 'idle' }
} as AirbnbReservationState;
