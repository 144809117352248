import { HospyThemeProvider } from '@hospy/shared-components';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ErrorBoundaryFallback from 'libs/hospy-ui/src/lib/organisms/error-boundary-fallback';
import FeedbackEnableCookies from 'libs/hospy-ui/src/lib/organisms/feedback-enable-cookies';

import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './base-theme.css';
import App from './components/app/app';
import { GlobalStyles } from './components/global-styles';
import { store } from './store';

console.log(
	window.location.href.includes('dev.hospy') ? 'development' : 'production'
);

Sentry.init({
	dsn: 'https://c058f14e9f7442b791e9b49312d806b6@o4504636114927616.ingest.sentry.io/4504996119117824',
	tracesSampleRate: 1.0,
	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,
	integrations: [new BrowserTracing(), new Sentry.Replay()],
	// environment: process.env['NODE_ENV'] || 'development'
	environment: window.location.href.includes('dev.hospy')
		? 'development'
		: 'production'
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<Provider store={store}>
		<HospyThemeProvider>
			<GlobalStyles />
			<FeedbackEnableCookies>
				<BrowserRouter>
					<Sentry.ErrorBoundary
						fallback={(props) => (
							<ErrorBoundaryFallback {...props} />
						)}
					>
						<App />
					</Sentry.ErrorBoundary>
				</BrowserRouter>
			</FeedbackEnableCookies>
		</HospyThemeProvider>
	</Provider>
);
